import {Button, Modal, ModalBody, ModalContent, ModalHeader} from "@nextui-org/react";
import {useState} from "react";
import axios from "../axios";
import {toast} from "react-toastify";

const TestServiceModal = () => {
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const submit = () => {
        setLoading(true);

        axios.post("/createTest", {}).then(r => {
            setOpen(false)
            toast.success("سرور جدید ساخته شد")
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }).catch((err) => {
            toast.error(err.response.data.message)
        }).finally(() => setLoading(false))
    }

    return (
        <>
            <Button color="secondary" onClick={
                () => setOpen(true)
            }
                    size="lg"
            >
                ساخت سرویس تست
            </Button>

            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <ModalContent>
                    <ModalHeader>
                        آیا از ساخت سرویس اطمینان دارید ؟
                        <br/>
                        150 MB , 1 Hour
                    </ModalHeader>

                    <ModalBody>
                        <Button isLoading={loading} color="danger" onPress={submit}>بله</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default TestServiceModal;