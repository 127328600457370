import {Button, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem} from "@nextui-org/react";
import {useState} from "react";
import {times, traffics} from "../Utils";
import axios from "../axios";
import {toast} from "react-toastify";

const RenewIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none">
        <path d="M8 2v3M16 2v3M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
              stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M15.695 13.7h.009M15.695 16.7h.009M11.995 13.7h.01M11.995 16.7h.01M8.294 13.7h.01M8.294 16.7h.01"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
)
export default function RenewServiceModal({
                                              service_id
                                          }) {


    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState("1")
    const [traffic, setTraffic] = useState("20")
    const price = () => {
        let price;
        switch (traffic) {
            case "20":
                price = 65000;
                break;
            case "40":
                price = 100000;
                break;
            case "60":
                price = 160000;
                break;
            case "100":
                price = 300000;
                break;
        }

        return (price * parseInt(time));
    };

    const submit = () => {
        setLoading(true)
        axios.post("/renew", {
            service: service_id,
            time: parseInt(time),
            traffic: parseInt(traffic)
        }).then((res) => {
            toast.success("سرویس تمدید شد")
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        }).catch((err) => {
            toast.error(err.response.data.message)
        }).finally(() => setLoading(false))
    }

    return <>
        <Button isIconOnly onPress={() => setOpen(true)} variant={"light"} color="warning"> <RenewIcon/> </Button>
        <Modal isOpen={open} onClose={() => setOpen(false)}>
            <ModalContent>
                <ModalHeader>
                    <h3>Renew | {service_id}</h3>
                </ModalHeader>
                <ModalBody>
                    <h3>
                        Price : {new Intl.NumberFormat().format(price())} T
                    </h3>
                    <Select
                        variant="faded"
                        label="Select a time"
                        fullWidth
                        disallowEmptySelection
                        defaultSelectedKeys={["1"]}
                        onChange={(e) => setTime(e.target.value)}
                    >
                        {times.map((t) => (
                            <SelectItem key={t} value={t}>
                                {t}
                            </SelectItem>
                        ))}
                    </Select>

                    <Select
                        variant="faded"
                        label="Select a traffic"
                        fullWidth
                        disallowEmptySelection
                        defaultSelectedKeys={["20"]}
                        onChange={(e) => setTraffic(e.target.value)}
                    >
                        {traffics.map((t) => (
                            <SelectItem key={t} value={t}>
                                {t}
                            </SelectItem>
                        ))}
                    </Select>
                    <Button color="success" className="text-white" isLoading={loading} onPress={submit}>
                        تمدید سرویس
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}