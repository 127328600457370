import {Button, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem} from "@nextui-org/react";
import {useState} from "react";
import {countries, countryIcon} from "../Utils";
import axios from "../axios";
import {toast} from "react-toastify";

const GlobIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none">
        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" stroke="currentColor"
              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M8 3h1a28.424 28.424 0 0 0 0 18H8M15 3a28.424 28.424 0 0 1 0 18" stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M3 16v-1a28.424 28.424 0 0 0 18 0v1M3 9a28.424 28.424 0 0 1 18 0" stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
)

export default function ChangeCountryModal({
                                               service_id,
                                               currentCountry
                                           }) {
    const [open, setOpen] = useState(false)
    const [country, setCountry] = useState(countries.filter((c) => c != currentCountry)[0])
    const [loading, setLoading] = useState(false)

    const submit = () => {
        setLoading(true)
        axios.post("/changecountry", {
            service: service_id,
            country: country
        }).then(r => {
            setOpen(false)
            toast.success("سرور سرویس مورد نظر تغییر کرد")
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }).catch((err) => {
            toast.error(err.response.data.message)
        }).finally(() => setLoading(false))
    }

    return (<>
        <Button isIconOnly onPress={() => setOpen(true)} variant={"shadow"} color="danger"> <GlobIcon/> </Button>
        <Modal isOpen={open} onClose={() => setOpen(false)}>
            <ModalContent>
                <ModalHeader>
                    <h3>Change Country</h3>
                </ModalHeader>

                <ModalBody>
                    <Select
                        variant="faded"
                        label="Select a country"
                        fullWidth
                        disallowEmptySelection
                        defaultSelectedKeys={[country]}
                        onChange={(e) => setCountry(e.target.value)}
                        startContent={countryIcon(country)}
                    >
                        {countries.map((country) => (
                            <SelectItem key={country} value={country} startContent={countryIcon(country)}
                                        isDisabled={country === currentCountry}>
                                {new Intl.DisplayNames(['en'], {type: 'region'}).of(country)}
                            </SelectItem>
                        ))}
                    </Select>

                    <Button color="danger" isLoading={loading} onPress={submit}>تغییر سرور</Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>)
}