import {Button, Input, Modal, ModalBody, ModalContent, ModalHeader} from "@nextui-org/react";
import {useState} from "react";
import axios from "../axios";

export default function ChargeWalletModal() {
    const [open, setOpen] = useState(false)
    const [amount, setAmount] = useState(10000)
    const [loading, setLoading] = useState(false)

    const submit = () => {
        setLoading(true)
        axios.post("/checkout", {
            amount: amount
        }).then((res) => {
            window.location.replace(res.data.redirect)
        })
    }

    return (
        <>
            <Button color="warning" className="text-white" size="sm" fullWidth onPress={() => setOpen(true)}> افزایش
                موجودی</Button>
            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <ModalContent>
                    <ModalHeader>
                        <h3>Charge Wallet</h3>
                    </ModalHeader>

                    <ModalBody>
                        <Input type="number" min={10000} max={10000000} defaultValue={10000}
                               endContent={<span>تومان</span>} onChange={(e) => setAmount(e.target.value)}/>
                        <Button onPress={submit} isLoading={loading} color={"secondary"}
                                isDisabled={amount < 0 || amount > 10000000}>شارژ حساب</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}