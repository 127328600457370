import {Button, Input} from "@nextui-org/react";
import {useState} from "react";
import axios from "../axios";
import {toast} from "react-toastify";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);


    const submit = () => {
        setLoading(true);
        axios.post("/login", {
            username: username,
            password: password
        }).then((res) => {
            toast.success("شما وارد شدید !")
            localStorage.setItem("token", res.data.token)
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }).catch((err) => {
            toast.error(err.response.data.message);
        }).finally(() => setLoading(false))
    }

    return (
        <div className="container">

            <div className="flex items-center flex-col gap-4" style={{
                width: "250px"
            }}>
                <h1>
                    HelixConnect
                </h1>
                <Input placeholder="Username" size="xl" onChange={(e) => setUsername(e.target.value)}/>
                <Input placeholder="Password" size="xl" type="password" onChange={(e) => setPassword(e.target.value)}/>


                <Button size="xl" color="secondary" fullWidth isDisabled={!username || !password} isLoading={loading}
                        onPress={submit}>
                    Login
                </Button>
            </div>
        </div>
    )
}