import {Button, Modal, ModalBody, ModalContent, ModalHeader} from "@nextui-org/react";
import {useState} from "react";
import QRCode from "react-qr-code";
import {toast} from "react-toastify";

const ScanIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none">
        <path
            d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9M15 2h2.5C19.99 2 22 4.01 22 6.5V9M22 16v1.5c0 2.49-2.01 4.5-4.5 4.5H16M9 22H6.5C4.01 22 2 19.99 2 17.5V15M10.5 7v2c0 1-.5 1.5-1.5 1.5H7c-1 0-1.5-.5-1.5-1.5V7C5.5 6 6 5.5 7 5.5h2c1 0 1.5.5 1.5 1.5ZM18.5 7v2c0 1-.5 1.5-1.5 1.5h-2c-1 0-1.5-.5-1.5-1.5V7c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5ZM10.5 15v2c0 1-.5 1.5-1.5 1.5H7c-1 0-1.5-.5-1.5-1.5v-2c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5ZM18.5 15v2c0 1-.5 1.5-1.5 1.5h-2c-1 0-1.5-.5-1.5-1.5v-2c0-1 .5-1.5 1.5-1.5h2c1 0 1.5.5 1.5 1.5Z"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
)

export default function QrCodeModal({
                                        link
                                    }) {
    const [open, setOpen] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(link).then(r => toast.success("لینک کپی شد !"))

    }

    return <>
        <Button isIconOnly variant={"light"} color="success" onPress={() => setOpen(true)}> <ScanIcon/> </Button>
        <Modal isOpen={open} onClose={() => setOpen(false)}>
            <ModalContent>

                <ModalBody className="flex items-center">
                    <QRCode value={link}/>
                    <Button color="danger" fullWidth onPress={() => copy()}>
                        کپی کردن لینک
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}