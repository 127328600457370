import {Button, Modal, ModalBody, ModalContent, ModalHeader} from "@nextui-org/react";
import {useState} from "react";
import axios from "../axios";
import {toast} from "react-toastify";

const DeleteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" role="presentation">
        <g transform="translate(3 2)" fill="currentColor">
            <path
                d="M5.132,19.961A2.916,2.916,0,0,1,2.2,17.134c-.313-2.847-.836-9.577-.846-9.645a.791.791,0,0,1,.191-.558A.708.708,0,0,1,2.068,6.7H15.939a.724.724,0,0,1,.523.234.745.745,0,0,1,.181.558c0,.068-.533,6.809-.837,9.645a2.918,2.918,0,0,1-3,2.827C11.515,19.99,10.249,20,9,20,7.681,20,6.387,19.99,5.132,19.961ZM.714,5.091A.73.73,0,0,1,0,4.357v-.38a.724.724,0,0,1,.714-.734H3.63A1.282,1.282,0,0,0,4.871,2.228l.152-.682A1.989,1.989,0,0,1,6.935,0h4.129a1.987,1.987,0,0,1,1.9,1.5l.163.73a1.28,1.28,0,0,0,1.241,1.016h2.916A.723.723,0,0,1,18,3.977v.38a.73.73,0,0,1-.713.734Z"
                fill="var(--colors-reverse)"></path>
        </g>
    </svg>
);
export default function DeleteServiceModal({
    service_id
                                           }) {
    const [open, setOpen] = useState(false)
    const [loading ,setLoading] = useState(false)

    const submit = () => {
        setLoading(true)
        axios.post("/delete", {
            service: service_id
        }).then(r => {
            setOpen(false)
            toast.success("سرویس حذف شد")
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }).catch((err) => {
            toast.error(err.response.data.message)
        }).finally(() => setLoading(false))
    }

    return (
        <>
            <Button isIconOnly onPress={() => setOpen(true)} variant={"light"} color="secondary"> <DeleteIcon/> </Button>

            <Modal isOpen={open} onClose={() => setOpen(false)}>
                <ModalContent>
                    <ModalHeader>
                        آیا از حذف سرویس {service_id} اطمینان دارید ؟
                    </ModalHeader>

                    <ModalBody>
                        <Button isLoading={loading} color="danger" onPress={submit}>بله</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}