import {Button, Modal, Input, Radio, ModalContent, ModalHeader, ModalBody, Select, SelectItem} from "@nextui-org/react";
import {useState} from "react";
import {countries, countryIcon, times, traffics} from "../Utils";
import axios from "../axios";
import {toast} from "react-toastify";

const CreateServiceModal = () => {
    const [name, setName] = useState("")
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState("DE");
    const [time, setTime] = useState("1")
    const [traffic, setTraffic] = useState("20");
    const [loading, setLoading] = useState(false)
    const price = () => {
        let price;
        switch (traffic) {
            case "20":
                price = 65000;
                break;
            case "40":
                price = 100000;
                break;
            case "60":
                price = 160000;
                break;
            case "100":
                price = 300000;
                break;
        }

        return (price * parseInt(time));
    };

    const submit = () => {
        setLoading(true);

        axios.post("/create", {
            name: name,
            country: country,
            time: parseInt(time),
            traffic: parseInt(traffic)
        }).then(r => {
            setOpen(false)
            toast.success("سرور جدید ساخته شد")
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }).catch((err) => {
            toast.error(err.response.data.message)
        }).finally(() => setLoading(false))
    }


    return (
        <>
            <Button color="danger" onClick={
                () => setOpen(true)
            }
                    size="lg"
            >
                سرویس جدید
            </Button>

            <Modal
                aria-labelledby="modal-title"
                open={open}
                isOpen={open}
                onClose={() => setOpen(false)}
                width={"500px"}
            >
                <ModalContent>
                    <ModalHeader>
                        <h3>
                            New Service
                        </h3>
                    </ModalHeader>

                    <ModalBody>
                        <h3>
                            Price : {new Intl.NumberFormat().format(price())} T
                        </h3>
                        <Input
                            variant="faded"
                            fullWidth
                            size="lg"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="name"
                        />
                        <Select
                            variant="faded"
                            label="Select a country"
                            fullWidth
                            disallowEmptySelection
                            defaultSelectedKeys={["DE"]}
                            onChange={(e) => setCountry(e.target.value)}
                            startContent={countryIcon(country)}
                        >
                            {countries.map((country) => (
                                <SelectItem key={country} value={country} startContent={countryIcon(country)}>
                                    {new Intl.DisplayNames(['en'], {type: 'region'}).of(country)}
                                </SelectItem>
                            ))}
                        </Select>
                        <Select
                            variant="faded"
                            label="Select a time"
                            fullWidth
                            disallowEmptySelection
                            defaultSelectedKeys={["1"]}
                            onChange={(e) => setTime(e.target.value)}
                        >
                            {times.map((t) => (
                                <SelectItem key={t} value={t}>
                                    {t}
                                </SelectItem>
                            ))}
                        </Select>

                        <Select
                            variant="faded"
                            label="Select a traffic"
                            fullWidth
                            disallowEmptySelection
                            defaultSelectedKeys={["20"]}
                            onChange={(e) => setTraffic(e.target.value)}
                        >
                            {traffics.map((t) => (
                                <SelectItem key={t} value={t}>
                                    {t}
                                </SelectItem>
                            ))}
                        </Select>
                        <Button color="success" className="text-white" isDisabled={!name} onPress={submit}
                                isLoading={loading}>
                            ساخت سرویس
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateServiceModal;