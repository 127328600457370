import {number_format} from "../Utils";
import ChargeWalletModal from "./ChargeWalletModal";
import CreateServiceModal from "./CreateServiceModal";
import {Button, Input} from "@nextui-org/react";

export default function SuperDashboard({
                                           user: user
                                       }) {
    return (
        <div className="flex justify-center" style={{
            height: "100vh"
        }}>
            <div className=" flex flex-col" style={{
                width: "80%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "25px",
                    marginTop: "50px"
                }} className={"r-container"}>
                    <h1>سامان پنل</h1>
                    <div>
                        <h3>
                            Balance : {number_format(user.balance)} T
                        </h3>
                        <ChargeWalletModal/>
                    </div>
                    <CreateServiceModal/>
                </div>

                <div>
                    <Input variant="faded" label="User" endContent={<Button color="danger">جستجو</Button>}/>
                </div>

            </div>
        </div>
    )
}