import Login from "./components/Login";

import 'react-toastify/dist/ReactToastify.css';
import 'flag-icons/css/flag-icons.min.css';
import {ToastContainer} from "react-toastify";
import Dashboard from "./components/Dashboard";
import {useEffect, useState} from "react";

const App = () => {
    const [token, setToken] = useState(localStorage.getItem("token"));

    useEffect(() => {
        setToken(localStorage.getItem("token"))
    }, [localStorage])

    return (

        <>
            {
                token !== null ? <Dashboard/> : <Login/>
            }

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>


    )
}

export default App;