import {useEffect, useState} from "react";
import axios from "../axios";
import UserDashboard from "./UserDashboard";
import SuperDashboard from "./SuperDashboard";

export default function Dashboard() {
    const [user, setUser] = useState({});
    const [services, setServices] = useState([]);

    useEffect(() => {
        axios.get("/fetch").then((res) => {
            setServices(res.data.services)
            setUser(res.data.user)
        }).catch((err) => {
            localStorage.removeItem("token");
            window.location.reload()
        })
    }, []);

    return (<>
        {
            user.is_super ? <SuperDashboard user={user}/> : <UserDashboard user={user} services={services}/>
        }
    </>)
}