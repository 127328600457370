import {countryIcon, number_format} from "../Utils";
import ChargeWalletModal from "./ChargeWalletModal";
import CreateServiceModal from "./CreateServiceModal";
import {
    Button,
    getKeyValue,
    Link, Switch,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow
} from "@nextui-org/react";
import QrCodeModal from "./QrCodeModal";
import ChangeCountryModal from "./ChangeCountryModal";
import RenewServiceModal from "./RenewServiceModal";
import {useEffect, useState} from "react";
import axios from "../axios";
import DeleteServiceModal from "./DeleteServiceModal";
import TestServiceModal from "./TestServiceModal";
import {toast} from "react-toastify";

export default function UserDashboard({
                                          user,
                                          services
                                      }) {


    const disable = (service, checked) => {
        axios.post("/disable", {
            service: service,
            disable: checked
        }).then(r => {
            toast.success("موفق")
        })
    }

    return (
        <div className="flex justify-center" style={{
            height: "100vh"
        }}>
            <div className=" flex flex-col" style={{
                width: "80%"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "25px",
                    marginTop: "50px"
                }} className={"r-container"}>
                    <h1>سرویس ها</h1>
                    <div>
                        <h3>
                            Balance : {number_format(user.balance)} T
                        </h3>
                        <ChargeWalletModal/>
                    </div>
                    <div style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column"
                    }}>
                        <CreateServiceModal/>
                        <TestServiceModal/>
                    </div>
                </div>
                <div>
                    <Table
                        css={{minWidth: "100%", height: "calc($space$14 * 10)"}}
                        color="secondary"
                    >
                        <TableHeader>
                            <TableColumn key="id">id</TableColumn>
                            <TableColumn key="name">Name</TableColumn>
                            <TableColumn key="country">Country</TableColumn>
                            <TableColumn key="traffic">Total Traffic</TableColumn>
                            <TableColumn key="traffic_used">Used Traffic</TableColumn>
                            <TableColumn key="expire_date">Expire Date</TableColumn>
                            <TableColumn key="disable">Disable</TableColumn>
                            <TableColumn key="button">#</TableColumn>
                        </TableHeader>

                        <TableBody>
                            {services.map((row) => <TableRow key={row.id}>
                                    {(columnKey) => {
                                        if (columnKey === "button") {
                                            return <TableCell className="flex gap-2">
                                                <QrCodeModal link={row.link}/>
                                                <ChangeCountryModal service_id={row.id} currentCountry={row.country}/>
                                                <RenewServiceModal service_id={row.id}/>
                                                <DeleteServiceModal service_id={row.id}/>
                                            </TableCell>
                                        } else if (columnKey === "country") {
                                            return <TableCell className="flex gap-2">
                                                {countryIcon(row.country)}
                                                {new Intl.DisplayNames(['en'], {type: 'region'}).of(row.country)}
                                            </TableCell>
                                        } else if (columnKey === "disable") {
                                            return <TableCell>
                                                <Switch defaultSelected={!!row.is_disabled} isDisabled={row.is_ended} onChange={(e) => disable(row.id, e.target.checked)}></Switch>
                                            </TableCell>
                                        }
                                        return <TableCell>{getKeyValue(row, columnKey)}</TableCell>;
                                    }}
                                </TableRow>
                            )
                            }
                        </TableBody>

                    </Table>
                </div>
                <div className="flex flex-col gap-2" style={{
                    width: "200px",
                    marginTop: "10px",
                    marginBottom: "10px"
                }}>
                    <Button color="primary" as={Link} href="http://dl.helixconnect.site/v2rayNG_1.7.34.apk">Android
                        V2rayNG</Button>
                    <Button color="danger" as={Link} href="http://dl.helixconnect.site/v2rayN-Core.zip">Windows
                        v2rayCore</Button>
                    <Button color="secondary" as={Link} href="https://apps.apple.com/us/app/fair-vpn/id1533873488">IOS
                        fairvpn</Button>
                </div>
                <div style={{
                    marginTop: "auto"
                }}>
                    <Button color="danger" fullWidth onPress={() => {
                        localStorage.removeItem("token")
                        window.location.reload()
                    }}>خروج از حساب کاربری </Button>
                </div>
            </div>
        </div>
    )
}